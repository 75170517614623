body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
html,
body {
  height: 100%;
}

* {
  font-family: Montserrat Alternates, sans-serif;
}

.search-form .ant-form-item {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #92929d;
}

.ant-btn {
  box-shadow: none !important;
}

.product-container .ant-spin-container,
.product-container .ant-spin-nested-loading {
  height: 100% !important;
}

.ant-form-item {
  margin-bottom: 6px !important;
}

.icon {
  filter: invert(62%) sepia(0%) saturate(58%) hue-rotate(158deg) brightness(83%)
    contrast(101%) !important;
}

.active-icon {
  filter: invert(12%) sepia(7%) saturate(0%) hue-rotate(189deg) brightness(98%)
    contrast(93%) !important;
}

.active-control {
  filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(2%)
    hue-rotate(58deg) brightness(110%) contrast(100%);
}

.theme-icon {
  filter: invert(100%) sepia(5%) saturate(266%) hue-rotate(358deg)
    brightness(118%) contrast(100%) !important;
}

.active-theme-icon {
  filter: invert(0%) sepia(23%) saturate(46%) hue-rotate(335deg) brightness(91%)
    contrast(76%) !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: #3ae4b4;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-bottom {
  z-index: 1;
}

.ant-table-cell-row-hover {
  background-color: unset !important;
}
.banner-car .slick-list,
.banner-car .slick-track,
.banner-car .slick-slide,
.ant-carousel {
  height: 100% !important;
}
.banner-car .slick-slide > div {
  height: 100% !important;
}

.banner-car .slick-slide > div:focus-visible {
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  /* or another breakpoint */
  * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard syntax */
  }
}

.centrify {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.confBtn {
  outline: none;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.25s;
  cursor: pointer;
  border: 0;
  padding: 24px;
  font-size: 32px;
}

.confBtn div {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0, 1);
  transform-origin: left;
}

.confBtn:hover span,
.confBtn:focus span {
  /* color: #fff; */
  text-shadow: 0 0 5px #555;
}

.confBtn:active span {
  text-shadow: 0 2px 8px #333;
}

.confBtn:active div {
  transform: scale(1);
  transition: all 2s cubic-bezier(0.55, 0, 0.1, 1);
}

.long-press-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.long-press-button {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #ff5722;
  width: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
